import axios from "axios";

const formatDate = (dateString: any) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
const listInventory = (move: any): string => {
  const items: string[] = [];

  move?.roomDetails?.forEach((roomDetail: any) => {
    roomDetail?.room_images?.forEach((roomImage: any) => {
      roomImage?.items?.forEach((item: any) => {
        items.push(item?.item_name);
      });
    });
  });
  return items.join(", ");
};

const checkFromAddress = (moveReq: any) => {
  if (moveReq?.home_address !== null) {
    return moveReq.home_address?.toString();
  }
  if (moveReq?.apartment !== null) {
    return moveReq.apartment?.apt_address?.toString();
  }
  if (moveReq?.storage !== null) {
    return moveReq.storage?.address?.toString();
  }
  if (moveReq?.combo_home_storage !== null) {
    return moveReq.combo_home_storage?.home_address?.toString();
  }
  if (moveReq?.combo_apartment_storage !== null) {
    return moveReq.combo_apartment_storage?.apartment?.apt_address.toString();
  }
  return "null";
};
const pushMoveDataToCRM = async (move: any, tenant: string) => {
  let apiURL = "";
  if (tenant === "summitmoving") {
    apiURL =
      "https://api.smartmoving.com/api/leads/from-provider/v2?providerKey=c1c12b7d-71a4-4a3c-8460-ac160004aa39";
  } else if (tenant === "krupp") {
    apiURL =
      "https://api.smartmoving.com/api/leads/from-provider/v2?providerKey=c1c12b7d-71a4-4a3c-8460-ac160004aa39&branchId=6ab12d2c-47a3-486b-81f3-b11c00cb19f0";
  } else if (tenant === "movebees") {
    apiURL =
      "https://api.smartmoving.com/api/leads/from-provider/v2?providerKey=c1c12b7d-71a4-4a3c-8460-ac160004aa39&branchId=dc8050ac-be5f-43d4-a9e6-b17f01199b30";
  } else if (tenant === "rockstarspromoverspartial") {
    apiURL =
      "https://api.smartmoving.com/api/leads/from-provider/v2?providerKey=cc2476ba-65e0-4a84-9811-aae6017effab";
  }
  if (apiURL !== "") {
    const leadData = {
      fullName: move.mover_name?.toString() || "",
      phoneNumber: move.move_phone_number?.toString() || "",
      extension: "",
      userOptIn: "false",
      email: move.mover_email?.toString() || "",
      moveDate: formatDate(move.pickup_date_from) || "",
      OriginAddressFull: checkFromAddress(move) || "",
      DestinationAddressFull:
        move.delivery_details?.delivery_addresses[0]?.complete_address?.toString() ||
        "",
      bedrooms: move.total_bedrooms?.toString() || "",
      totalCubicFeetApproximation: move.total_cubic_feet?.toString() || "",
      totalDistance: move.move_distance?.toString() || "",
      inventoryItems: listInventory(move) || "",
      totalWeightInKG:
        (Number(move.total_cubic_feet) * 7 * 0.453592).toFixed(2).toString() ||
        "",
      totalWeightInPounds: Number(move.total_cubic_feet * 7).toString() || "",
      leadProviderName: "WeMove",
    };
    try {
      const response = await axios.post(apiURL, leadData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        return true;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error message:", error.message);
      } else {
        console.error("Unexpected error:", error);
      }
    }
  }
};
export default pushMoveDataToCRM;
